// Third party
import React from "react";
import * as PropTypes from "prop-types";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layout";
import Container from "../components/Container";
import Section from "../components/Section";
import Breadcrumb from "../components/Breadcrumb";
import Seo from "../components/seo";

class TestimonialTemplate extends React.Component {
  render() {
    const testimonial = this.props.data.contentfulTestimonial;
    const {
      title,
      slug,
      body: {
        childMarkdownRemark: { html, excerpt },
      },
    } = testimonial;
    const breadcrumbs = [
      {
        id: 1,
        title: "Home",
        slug: "/",
      },
      {
        id: 2,
        title: "Testimonials",
        slug: "/testimonials",
      },
      {
        id: 3,
        title: title,
        slug: `/testimonials/${slug}`,
      },
    ];
    return (
      <Layout>
        <Seo
          title={`${title}`}
          description={`${excerpt}`}
          slug={`testimonials/${slug}`}
        />
        <Breadcrumb items={breadcrumbs} />
        <Section>
          <Container>
            <div className="grid">
              <div className="grid__col grid__col--3-of-5 grid__col--centered">
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    );
  }
}

TestimonialTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TestimonialTemplate;

export const pageQuery = graphql`
  query testimonialQuery($slug: String!) {
    contentfulTestimonial(slug: { eq: $slug }) {
      id
      title
      slug
      body {
        id
        childMarkdownRemark {
          excerpt
          html
        }
      }
    }
  }
`;
